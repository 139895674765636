// @flow
import type {Node} from 'react'
import React from 'react'
import {graphql} from 'react-relay'

import useRefetchableLazyLoadQuery from 'utils/hooks/useRefetchableLazyLoadQuery'

import type {
  ProfileGoalsQuery as Query,
} from './__generated__/ProfileGoalsQuery.graphql'

type Props = $ReadOnly<{
  orgDatabaseId: string,
  personDatabaseId: string,
}>

const profileGoalsQuery = graphql`
query ProfileGoalsQuery(
  $orgDatabaseId: String!,
  $personDatabaseId: String!,
  ) {
    organization(databaseId: $orgDatabaseId) {
      databaseId
      viewerIsMember
      viewerIsSuperUser

      person(databaseId: $personDatabaseId) {
        databaseId
      }
    }
  }
`

function ProfileGoals({orgDatabaseId, personDatabaseId} : Props): null | Node {
  const queryVariables = {orgDatabaseId, personDatabaseId}
  const [{organization}] = useRefetchableLazyLoadQuery<Query>(profileGoalsQuery, queryVariables)
  const person = organization?.person
  const canView = organization && person && (organization.viewerIsMember || organization.viewerIsSuperUser)

  if (!canView)
    return null

  return (
    <turbo-frame
      id="goals-and-targets"
      src={`/organizations/${orgDatabaseId}/people/${personDatabaseId}/goals${window.location.search}`}
    />
  )
}

export default ProfileGoals
