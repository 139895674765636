// react button to live in menu item to popup project modal

import React, {useState} from 'react'
import NewProjectFormRails from 'components/projects/NewProjectFormRails'

type Props = $ReadOnly<{
  orgDatabaseId: string,
  circleDatabaseId: string,
  parentGoalOrTarget: string,
  supportedRoleId: string,
  defaultRoleId: string,
}>

function AddProjectMenuItem({orgDatabaseId, circleDatabaseId,
  parentGoalOrTarget, supportedRoleId, defaultRoleId}: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const onClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsOpen(true)
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#" onClick={onClick}>
      {I18n.t('projects.my_projects.add_project')}
      {isOpen
        && (
        <NewProjectFormRails
          orgDatabaseId={orgDatabaseId}
          circleDatabaseId={circleDatabaseId}
          defaultRoleId={defaultRoleId}
          roleDatabaseId={supportedRoleId}
          parentGoalOrTarget={parentGoalOrTarget}
          supportedRoleId={supportedRoleId}
          close={() => setIsOpen(false)}
        />
        )
      }
    </a>
  )
}

export default AddProjectMenuItem
