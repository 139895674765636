// @flow
import type {Element} from 'react'
import React, {useCallback, useEffect, useRef} from 'react'
import ReactSelect from 'react-select'
import util from 'utils/MiscUtils'

export type Props = $ReadOnly<{
  defaultValue?: Object,
  id: string,
  isMulti?: boolean,
  isSearchable?: boolean,
  name: string,
  options: Array<any>,
  wrapperClass?: string,
}>

function RailsSelect({defaultValue, id, isMulti, isSearchable, name, options, wrapperClass}: Props): Element<"div"> {
  const wrapperRef = useRef(null)
  const selectRef = useRef(null)

  // Hack for bubbling 'change' events:
  // focus and blur events naturally bubble from the underlying ReactSelect component.
  // default/native change events do not bubble, so we have to manually (re-) dispatch them with {bubbles: true} here.
  // setting handlers on for these events has tricks:
  // $form.on('change', 'input, select')  -> will not work. the synthetic event comes from a div
  // $form.on('change', 'div') -> will work but will get an event per div that is bubbled through.
  // $form.on('change', 'div.js-slick-auto-save') -> will work correctly, assuming that exactly one div has that class.
  const handleChange = useCallback(() => {
    if (wrapperRef.current)
      wrapperRef.current.dispatchEvent(new Event('change', {bubbles: true, cancelable: true}))
    else
      util.warn('wrapperRef.current is null')
  }, [wrapperRef])

  useEffect(() => {
    if (selectRef.current) {
      const availableIds = options.map((option) => option.value)
      const selectedIds = selectRef.current.getValue().map((option) => option.value)

      if (options.length === 1)
        selectRef.current.setValue(options)
      else if (selectedIds.filter((optionId) => availableIds.includes(optionId)).length === 0)
        selectRef.current.setValue(null)
    }
  }, [selectRef, options])

  return (
    <div ref={wrapperRef} className={wrapperClass}>
      <ReactSelect
        defaultValue={defaultValue}
        id={id}
        isMulti={isMulti}
        isSearchable={isSearchable}
        name={name}
        onChange={handleChange}
        options={options}
        // $FlowFixMe[incompatible-type]
        ref={selectRef}
      />
    </div>
  )
}

export default RailsSelect
